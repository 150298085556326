import React from 'react';
import { Link } from 'react-router-dom';

interface JobPosting {
  id: number;
  title: string;
  department: string;
  location: string;
  type: string;
  description: string;
}

export const jobListings: JobPosting[] = [
  {
    id: 1,
    title: "Associate Software Engineer",
    department: "Engineering",
    location: "Remote",
    type: "Full-time/Part-time",
    description: "We are looking for an Associate Software Engineer proficient in Python and React."
}
];

const Jobs = () => {
  return (
    <div id="jobs" className="container mx-auto px-4 py-16 mt-16">
      <h1 className="text-4xl font-bold text-center mb-8">Open Positions</h1>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {jobListings.map((job) => (
          <div key={job.id} className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-2">{job.title}</h2>
            <div className="mb-4">
              <span className="text-gray-600">{job.department} • {job.location} • {job.type}</span>
            </div>
            <p className="text-gray-700 mb-4">{job.description}</p>
            <Link 
              to={`/job/${job.id}`}
              className="inline-block bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Apply Now
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Jobs;
