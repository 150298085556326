import React from "react";
import { useParams } from "react-router-dom";
import { jobListings } from "./Jobs";

const JobDescription = () => {
  const { id } = useParams();
  const job = jobListings.find((job) => job.id === Number(id));

  if (!job) {
    return <div className="container mx-auto px-4 py-12">Job not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-4">{job.title}</h1>
        <div className="mb-6">
          <span className="text-gray-600">
            {job.department} • {job.location} • {job.type}
          </span>
        </div>
        <div className="prose max-w-none">
          <p className="text-gray-700 mb-6">{job.description}</p>
        </div>
        <div className="mt-8">
          <form method="POST" action="https://formsubmit.co/contact.remotechies@gmail.com" encType="multipart/form-data" className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="email" className="block text-gray-700">Email Address</label>
              <input 
                id="email"
                type="email" 
                name="email" 
                placeholder="Enter your email" 
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="message" className="block text-gray-700">Cover Letter</label>
              <textarea 
                id="message"
                name="message" 
                placeholder="Write your cover letter here" 
                className="w-full p-2 border rounded-md h-32"
                required
              ></textarea>
            </div>
            <div className="space-y-2">
              <label htmlFor="cv" className="block text-gray-700">Upload CV (PDF only)</label>
              <input 
                id="cv"
                type="file" 
                name="attachment" 
                accept="application/pdf" 
                className="w-full p-2 border rounded-md"
                required
              />
            </div>
            <button 
              type="submit" 
              className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700"
            >
              Send Application
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobDescription;
