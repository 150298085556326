// App.tsx

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import WebsiteBuilder from "./components/WebsiteBuilder";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Projects from "./components/Projects";
import Jobs from "./components/Jobs";
import JobDescription from "./components/JobDescription";

const JoinButton = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center">
      <button
        onClick={() => navigate("/jobs")}
        className="
          relative
          inline-flex items-center justify-center
          px-8 py-4
          text-lg font-semibold
          bg-gradient-to-r from-blue-600 to-blue-700
          text-white
          rounded-lg
          shadow-lg
          transform transition-all duration-300
          hover:scale-105
          hover:shadow-xl
          hover:from-blue-700 hover:to-blue-800
          active:scale-95
          before:content-['']
          before:absolute
          before:top-0 before:left-0
          before:w-full before:h-full
          before:bg-white
          before:opacity-0
          before:transition-opacity
          before:duration-300
          hover:before:opacity-10
          focus:outline-none
          focus:ring-2
          focus:ring-blue-500
          focus:ring-offset-2
        "
      >
        <span className="mr-2">Join with Us</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 inline-block transition-transform duration-300 group-hover:translate-x-1"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <Hero />
                  <Services />
                  <Projects />
                  <Contact />
                  <div id="jobs" className="m-4">
                    <JoinButton />
                  </div>
                </div>
              }
            />
            <Route path="/website-builder" element={<WebsiteBuilder />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/job/:id" element={<JobDescription />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
